/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';

/********************************* app styles **********************************/
@import './assets/styles/backgrounds.scss';
@import './assets/styles/inputs.scss';
@import './assets/styles/elements.scss';

@import '~video.js/dist/video-js.css';

@font-face {
    font-family: Rubik-Ligth;
    src: url('./assets/fonts/rubik/Rubik-Regular.ttf') format("opentype");
}

html, body { height: 100%; /* background-color: rgb(247, 248, 249); */ }
body { 
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    color : rgb(44,62,93);
    background-color: rgb(247, 248, 249); 
    // background-color: rgb(251,251,252);    
}
.container { margin: 1%; }

* {
    font-family: Rubik-Ligth;
}