.my_selector{
    padding:5px 5px;
    border-radius:5px;
    border:1px solid rgb(213,217,221)
}

.aplicar_span{
    color: rgb(213,217,221);
    font-weight:bolder;
    font-size:13px;
    margin-left:6px;
}